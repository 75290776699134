<template>
  <notifications
    group="custom-notification"
    class="custom-notification"
    :duration="5000"
    position="bottom right"
  >
    <template #body="props">
      <div class="success-template">
        <div class="success-template-icon">
          <div
            v-if="props.item.type === 'success'"
            class="success-icon"
          >
            <img
              src="../../../public/static/images/icons/check_icon.svg"
              alt="check-icon"
            >
          </div>
          <div
            v-else-if="props.item.type === 'error'"
            class="error-icon"
          >
            <img
              src="../../../public/static/images/icons/alert_triangle.svg"
              alt="alert-icon"
            >
          </div>
          <div
            v-else-if="props.item.type === 'info'"
            class="error-icon"
          >
            <img
              src="../../../public/static/images/icons/info_icon_active.svg"
              alt="alert-icon"
            >
          </div>
          <div
            v-else
            class="warning-icon"
          >
            <img
              src="../../../public/static/images/icons/delete_warning.svg"
              alt="delete-icon"
            >
          </div>
        </div>
        <div class="success-template-content">
          <div
            class="title-header"
            :class="{
              'success-template-title': props.item.type === 'success',
              'error-template-title': props.item.type === 'error',
              'warning-template-title': props.item.type === 'warning'
            }"
          >
            {{ props.item.title }}
          </div>
          <div
            class="success-template-text"
            v-html="props.item.text"
          />
        </div>
        <div
          class="success-template-close"
          @click="props.close"
        >
          <img
            src="../../../public/static/images/icons/close_icon.svg"
            alt="close-icon"
          >
        </div>
      </div>
    </template>
  </notifications>
</template>

<style scoped>
.custom-notification {
  width: 480px !important;
}
.success-template {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  margin: 5px;
  border-radius: 10px;
  background: #ffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.35);
  padding: 10px;
  position: relative;
}
.success-template,
.success-template > div {
  box-sizing: border-box;
}
.success-template-icon {
  flex: 0 1 auto;
  font-size: 32px;
  padding: 15px 10px 0;
}
.success-icon {
  background: #4c9e45;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  padding: 5px 10px;
}
.success-icon img {
  width: 18px;
  height: 18px;
}
.success-template-close {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}
.success-template-close:hover {
  opacity: 0.8;
}
.success-template-content {
  padding: 10px 40px 10px 10px;
}

.title-header {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 5px;
}

.success-template-title {
  color: #4c9e45;
}
.warning-template-title {
  color: #000000;
}

.warning-icon img {
  height: 38px;
  width: 38px;
}

.success-template-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.7);
}
.error-icon img {
  width: 40px;
  height: 40px;
}
.error-template-title {
  color: #b00505;
}

@media screen and (max-width: 767px) {
  .custom-notification {
    width: 100% !important;
  }
  .success-template-content {
    padding: 10px 20px 10px 10px;
  }
}
</style>
