import axios from 'axios';

/**
 * @param {Number} userId 
 * @returns success or failure of the request
 */

export async function getCoursesListByUserId(userId) {
  let trainingsList = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/lms/courseList/${userId}`)
    .then((response) => {
      trainingsList = response.data;
    })
    .catch((err) => {
      trainingsList = err.response.data;
    });
  return trainingsList;
}


export async function getAllTrainingTypes() {
  let trainingTypes = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/lms/all/trainingTypes`)
    .then((response) => {
      trainingTypes = response.data;
    })
    .catch((err) => {
      trainingTypes = err.response.data;
    });
  return trainingTypes;
}

export async function addLMSTrainings(payload) {
  let trainingTypes = [];
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/lms/add/training`, payload)
    .then((response) => {
      trainingTypes = response.data;
    })
    .catch((err) => {
      trainingTypes = err.response.data;
    });
  return trainingTypes;
}

export async function getSamlCode() {
  let samlToken = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/opensaml/v1/generateSAMLToken`)
    .then((response) => {
      samlToken = response.data;
    })
    .catch((err) => {
      samlToken = err.response.data;
    });
  return samlToken;
}

export async function downloadCertificate(userId, lmsCourseId) {
  let certificateDetails = {};
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/lms/trainingCertificateDetails/${userId}`, {
      params: {
        lmsCourseId
      }
    })
    .then((response) => {
      certificateDetails = response.data;
    })
    .catch((err) => {
      certificateDetails = err.response.data;
    });
  return certificateDetails;
}

export async function getTrainingsActiveStatus(userId) {
  let isTrainingsActive = false;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/lms/available/${userId}`)
    .then((response) => {
      isTrainingsActive = response.data;
    })
    .catch((err) => {
      console.log("Error happened for getting trainings active", err);
      isTrainingsActive = err.response.data;
    });
  return isTrainingsActive;
}

export async function getActiveTrainingsByUserId(userId) {
  let activeTrainingsList = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/lms/trainings/${userId}`)
    .then((response) => {
      activeTrainingsList = response.data.data;
    })
    .catch((err) => {
      console.log("Error happened for getting trainings active", err);
    });
  return activeTrainingsList;
}

export async function getActiveCoursesByAccountId(accountId) {
  let activeTrainingsList = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/lms/trainings/account/${accountId}`)
    .then((response) => {
      activeTrainingsList = response.data.data;
    })
    .catch((err) => {
      console.log("Error happened for getting trainings active", err);
    });
  return activeTrainingsList;
}

/**
 * lms_tour get api
 * is to get is new lms service or not to show the tour popup */
export async function getLMSTour() {
  let result;
  const url = `${process.env.VUE_APP_JAVA_API_URL}/lms_tour`;
  await axios
    .get(url)
    .then((response) => {
      result = response.data;
    })
    .catch((err) => {
      console.log('get lms tour java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * lms_tour put api
 * is to update that user has seen or skipped the tour to db */
export async function updateLMSTour(data) {
  let result;
  const url = `${process.env.VUE_APP_JAVA_API_URL}/lms_tour`;
  await axios
    .put(url, data)
    .then((response) => {
      result = response.data;
    })
    .catch((err) => {
      console.log('put lms tour java api error', err); // eslint-disable-line no-console
    });
  return result;
}
