/**
 * @author SwathiDivya Bhavaraju
 * @email swathidivya.bhavaraju@ul.com
 * @create date 2020-04-02 17:54:53
 * @modify date 2020-04-02 17:54:53
 * @desc [description]
 */
import {
  createApp
} from 'vue';
import Notifications from '@kyvg/vue3-notification';
import VueClipboard from 'vue3-clipboard';
import Multiselect from 'vue-multiselect';
import VuePdfApp from 'vue3-pdf-app';
import mitt from 'mitt';
import swal from 'sweetalert';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import 'hacktimer';
import router from './router';
import App from './App.vue';
import apiResponseInterceptor from './utils/apiResponseInterceptor';
import apiRequestInterceptor from './utils/apiRequestInterceptor';
import { beforeEach } from './router/hooks';
import { getCookie } from './utils/common';
import BaseIcon from './components/Common/BaseIcon.vue';
// Import the CSS or use your own!
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-datepicker-next/index.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import 'font-awesome/css/font-awesome.min.css';
import '../public/static/app/saas/main.scss';
import '../public/static/css/normalize.css';
import '../public/static/css/flag-icon.css';
import '../public/static/css/activitytracker-grid.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import 'element-plus/dist/index.css'

const app = createApp(App)
/**
 *  configuration
 */
app.config.productionTip = false;
app.config.devtools = true;
/** 
 * eventbus for vue3 
 */
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
window.emitter= emitter;
app.config.globalProperties.window = window;
/**
 * global component registration
 */
app.component('MultiSelect', Multiselect);
app.component('BaseIcon', BaseIcon);
app.component('VuePdfApp', VuePdfApp);
window.swal = swal;

/**
 * third party component registration
 */
app.use(Notifications);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});

/**
 * interceptor
 */
apiResponseInterceptor();
apiRequestInterceptor();

/**
 * router configuration
 */
app.use(router);
router.beforeEach(beforeEach);

/**
 * global mixin registration
 */
app.mixin({
  data() {
    return {
      get AuthorizationToken() {
        return {
          Authorization: getCookie('Authorization')
        };
      }
    };
  }
});

app.directive('click-outside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line func-names
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

/**
 *  initialization
 */
app.mount('#app');

/**
 * Application Insight configuration
 */
if (process.env.VUE_APP_APPLICATIONINSIGHTS_KEY) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.VUE_APP_APPLICATIONINSIGHTS_KEY,
      enableAutoRouteTracking: true
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}
