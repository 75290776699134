/**
 * @author Pawan Sadvale
 * @email Pawan.Sadvale@ul.com
 * @create date 2019-08-07 09:21:03
 * @modify date 2019-08-07 09:21:03
 * @desc common helper functions page
 */

/** passwordValidation pattern */
export const PasswordValidation = /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})|^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$.,+=( )_%^&*])(?=.{10,})|^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$.,+=( )_%^&*])(?=.{10,})|^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$.,+=( )_%^&*])(?=.{10,}))/;

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

/** removeEncodedChar method
 * to replace the special characters in place of encoded characters
 */
export function removeEncodedChar(str) {
  return str.replace(/%21/g, '!')
    .replace(/%27/g, "'")
    .replace(/%28/g, '(')
    .replace(/%29/g, ')')
    .replace(/%2A/g, '*')
    .replace(/%20/g, ' ')
    .replace(/%2C/g, ',')
    .replace(/%26/g, '&')
    .replace(/%40/g, '@')
    .replace(/%5B/g, '[')
    .replace(/%5D/g, ']')
    .replace(/%5F/g, '_');
}
