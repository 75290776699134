/**
 * @author Jaspal Dhaliwal(Jaspal.Dhaliwal@ul.com)
 * @create date 2019-07-26 09:21:03
 * @modify date 2019-07-26 09:21:03
 * @desc api services page
 */

/**
 * /regintel/metadata get api
 * is get the regintel data */
import axios from 'axios';

export async function getRegIntelMetaData() {
  let regIntelData = {};
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/regintel/metadata`).then((response) => {
    regIntelData = response.data.data;
  }).catch((err) => {
    console.log('/regintel/metadata get java api error', err); // eslint-disable-line no-console
  });
  return regIntelData;
}

/**
 * /content/upload post api
 * to upload the content */
export async function uploadLog(formData) {
  let result;
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/regintel/content/upload`, formData)
    .then((response) => {
      result = response;
    })
    .catch((err) => {
      console.log('regintel/content/upload java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * /regintel/metadata post api
 * to add the regintel data */
export async function addMetaData(formData) {
  let result;
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/regintel/metadata`, formData)
    .then((response) => {
      result = response;
    })
    .catch((err) => {
      console.log('regintel/metadata add java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * /regintel/metadata put api
 * to update the regintel data */
export async function updateMetaData(formData) {
  let result;
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/regintel/metadata`, formData)
    .then((response) => {
      result = response;
    })
    .catch((err) => {
      console.log('/regintel/metadata put api error', err); // eslint-disable-line no-console
    });
  return result;
}

export async function getAllRegIntelCountries() {
  let countries;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/countries`)
    .then((response) => {
      if (response.data) {
        countries = response.data.data;
      }
    })
    .catch((err) => console.log('get countries java api error', err)); // eslint-disable-line no-console
  return countries;
}

export async function getRemainingRegIntelCountries(accountId) {
  let countries;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/remaining/countries/${accountId}`)
    .then((response) => {
      if (response.data) {
        countries = response.data.data;
      }
    })
    .catch((err) => console.log('get countries java api error', err)); // eslint-disable-line no-console
  return countries;
}

export async function getAvailableRegIntelCountries(accountId) {
  let countries;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/available/countries/${accountId}`)
    .then((response) => {
      if (response.data) {
        countries = response.data.data;
      }
    })
    .catch((err) => console.log('get countries java api error', err)); // eslint-disable-line no-console
  return countries;
}

export async function isDefaultAvailable(accountId) {
  let countries;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/complimentary/country/available/${accountId}`)
    .then((response) => {
      if (response.data) {
        countries = response.data.data;
      }
    })
    .catch((err) => console.log('get countries java api error', err)); // eslint-disable-line no-console
  return countries;
}

export async function isAllCountriesSelected(addonId, accountId) {
  let countries;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/all/countries/available/${addonId}/${accountId}`)
    .then((response) => {
      if (response.data) {
        countries = response.data.data;
      }
    })
    .catch((err) => console.log('get countries java api error', err)); // eslint-disable-line no-console
  return countries;
}

export async function postDefaultCountry(formData) {
  let result;
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/plan/regulatory/intelligence/complimentary/country`, formData)
    .then((response) => {
      if (response.data) {
        result = response.data;
      }
    })
    .catch((err) => console.log('post default country java api error', err)); // eslint-disable-line no-console
  return result;
}

export async function getRegIntelArchive(accountId) {
  let result;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/archive/${accountId}`)
    .then((response) => {
      if (response.data) {
        result = response.data;
      }
    })
    .catch((err) => console.log('get archive status java api error', err)); // eslint-disable-line no-console
  return result;
}

export async function getRegIntelComplementaryCountry(accountId) {
  let result;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/complimentary/country/${accountId}`)
    .then((response) => {
      if (response.data) {
        result = response.data.data;
      }
    })
    .catch((err) => console.log('get intelligence/complimentary/country java api error', err)); // eslint-disable-line no-console
  return result;
}

export async function postRegIntelArchive(formData) {
  let result;
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/archive`, formData)
    .then((response) => {
      if (response.data) {
        result = response.data;
      }
    })
    .catch((err) => console.log('post default country java api error', err)); // eslint-disable-line no-console
  return result;
}

export async function putRegIntelArchive(formData) {
  let result;
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/regulatory/intelligence/archive`, formData)
    .then((response) => {
      if (response.data) {
        result = response.data;
      }
    })
    .catch((err) => console.log('post default country java api error', err)); // eslint-disable-line no-console
  return result;
}
