/** * @author SwathiDivya Bhavaraju * @email swathidivya.bhavaraju@ul.com * @create date 2019-12-23 12:29:04 * @modify date 2019-12-23 12:29:04 * @desc vue component */
<template>
  <div class="content-wrappper">
    <div class="row m-0">
      <div class="col-md-12 p-0">
        <form
          ref="form"
          method="post"
          target="my_frame"
        >
          <input
            v-model="token"
            type="hidden"
            name="token"
            value
          >
          <input
            v-model="nextUrl"
            type="hidden"
            name="nextUrl"
          >
          <input
            v-model="serviceType"
            type="hidden"
            name="serviceType"
          >
        </form>
        <iframe
          name="my_frame"
          class="iframe-content"
          frameborder="0"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getCookie } from '../../utils/common';
import * as urls from '../../utils/RS_Urls';

export default {
  props: {
    roleId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      token: '',
      nextUrl: '',
      serviceType: 'smartBuilder'
    };
  },
  created() {
    if (this.roleId === 6) {
      this.$router.push('/layout/PermissionAccess').catch(() => {});
    }
    [, this.token] = getCookie('Authorization').split('Bearer ');
    if (window.location.href.includes('nextUrl')) {
      this.nextUrl = unescape(window.location.href.split('nextUrl=')[1]);
    } else {
      this.nextUrl = urls[this.$route.meta.key] ? urls[this.$route.meta.key] : '';
    }
  },
  mounted() {
    this.$refs.form.action = `${process.env.VUE_APP_RS_URL}/api/login`;
    this.$refs.form.submit();
  }
};
</script>
<style scoped>
.submission-content {
  margin: 20px 5px;
}
.iframe-content {
  width: 100%;
  height: 89vh;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}
</style>
