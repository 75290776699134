import axios from 'axios';
import { sortBy } from 'lodash';
/**
 * services get api
 * to get all the premium services */
export async function getServices() {
  let services = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/market/place/premium/services`)
    .then((response) => {
      if (response.data) {
        services = sortBy(response.data.data.premium_services, 'sequence');
        localStorage.setItem('services', JSON.stringify(services));
      }
    })
    .catch((err) => {
      console.log('get services java api error', err); // eslint-disable-line no-console
    });
  return services;
}

/**
 * services/description get api
 * to get the description for services */
export async function getServiceDesc() {
  let services = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/market/place/premium/services/description`)
    .then((response) => {
      if (response.data && response.data.status_code === 200) {
        services = response.data.data;
      }
    })
    .catch((err) => {
      console.log('services/description java api error', err); // eslint-disable-line no-console
    });
  return services;
}

/**
 * services/summary get api
 * to get the summary based on the given service id */
export async function getServiceSummary(serviceId) {
  let services = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/market/place/premium/services/summary/${serviceId}`)
    .then((response) => {
      if (response.data) {
        services = response.data.data;
      }
    })
    .catch((err) => {
      console.log('services/summary java api error', err); // eslint-disable-line no-console
    });
  return services;
}

/**
 * premium/services/add post api
 * to add the premium service to account */
export async function addService(formData) {
  let data = '';
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/market/place/premium/services/add`, formData)
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      console.log('services/add java api error', err); // eslint-disable-line no-console
    });
  return data;
}

export async function getServiceInfo() {
  let data = '';
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/launchpad/admin/service/info`)
    .then((response) => {
      data = response.data.data;
    })
    .catch((err) => {
      console.log('get service java api error', err); // eslint-disable-line no-console
    });
  return data;
}

/**
 * activate/service post api
 * to activate the service for accountid */
export async function activateService(formData) {
  let data = '';
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/launchpad/activate/service`, formData)
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      console.log('activate/service java api error', err); // eslint-disable-line no-console
    });
  return data;
}

/**
 * /remove/service put api
 * to remove the activated service */
export async function removeService(formData) {
  let data = '';
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/launchpad/remove/service`, formData)
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      console.log('/remove/service java api error', err); // eslint-disable-line no-console
    });
  return data;
}

/**
 * /request/submit post api
 * to request the service */
export async function requestService(formData) {
  let data = '';
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/quote/request/submit`, formData)
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      console.log('/request/submit java api error', err); // eslint-disable-line no-console
    });
  return data;
}

export async function getAccountsList(accountSearchValue) {
  let accountsList = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/admin/account/${accountSearchValue}`)
    .then((response) => {
      accountsList = response.data.data;
    })
    .catch((err) => {
      console.log('accountList err', err); // eslint-disable-line no-console
    });
  return accountsList;
}

export async function getActiveServices(accountId) {
  let data = '';
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/market/place/premium/services/${accountId}`)
    .then((response) => {
      data = response.data.data;
    })
    .catch((err) => {
      console.log('/market/place/premium/services/ java api error', err); // eslint-disable-line no-console
    });
  return data;
}

/**
 * /gg/request post api
 * to post the gg request */
export async function requestGreenLightService(formData) {
  let data = '';
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/gg/quote/request`, formData)
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      console.log('/gg/quote.request python api error', err); // eslint-disable-line no-console
    });
  return data;
}
/**
 * services with rbac get api
 * to get all the premium services */
export async function getServicesListWithRBAC() {
  let services = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/market/place/servicerbac`)
    .then((response) => {
      if (response.data) {
        services = sortBy(response.data.data, 'sequence');
      }
    })
    .catch((err) => {
      console.log('get getServicesListWithRBAC java api error', err); // eslint-disable-line no-console
    });
  return services;
}
/**
 * services with regreportmarkets get api
 * to get all the markets */
export async function getRegReportMarket(accountId) {
  let data;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/regessentialreport/marketplace/metadata/${accountId}`)
    .then((response) => {
      data = response.data.data;
    })
    .catch((err) => {
      console.log('/regessentialreport/marketplace/metadata/ java api error', err); // eslint-disable-line no-console
    });
  return data;
}

export async function getRepAccountsList(searchValue) {
  let repAccountsList = [];
  const queryParams = {
    accountTypeId: 2
  }
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/admin/account/${searchValue}`, { params: queryParams})
    .then((response) => {
      repAccountsList = response.data.data;
    })
    .catch((err) => {
      throw err;
    });
  return repAccountsList;
}

/**
 * 
 * @param {Payload} payload 
 * @returns List of countries List
 */
export async function getRepCountriesListForAdmin(payload) {
  let countriesList = [];
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/launchpad/service/accountbasedincountryrepservice`, payload)
    .then((response) => {
      countriesList = response.data.data;
    })
    .catch((err) => {
      throw err;
    });
  return countriesList;
}

/**
 * 
 * @param {Payload} payload 
 * @returns List of countries List
 */
export async function saveCountriesForSelectedRepAccount(payload) {
  let savedCountriesInfo = {};
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/launchpad/service/incountryrepserviceactivation`, payload)
    .then((response) => {
      savedCountriesInfo = response.data;
    })
    .catch((err) => {
      throw err;
    });
  return savedCountriesInfo;
}
