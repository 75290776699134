/**
 * @author SwathiDivya Bhavaraju
 * @email swathidivya.bhavaraju@ul.com
 * @create date 2020-09-16 16:55:10
 * @modify date 2020-09-16 16:55:10
 * @desc [description]
 */
import axios from 'axios';
import $ from 'jquery';
import { getCookie } from '../utils/common';
/**
 * getUserPermissions get api
 * to get the permissions based on user/team member */
export async function getUserPermissions(userId, accountId, roleId) {
  let result = '';
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/rbac/permissions/${userId}/${accountId}/${roleId}`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.data;
      }
    })
    .catch((err) => {
      console.log('permissions template based on role java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * updateUserPermissions put api
 * to update the user/team permissions */
export async function updateUserPermissions(data, roleId) {
  let result = '';
  await axios.put(`${process.env.VUE_APP_JAVA_API_URL}/rbac/permissions/${data.user_id}/${data.account_id}/${roleId}`, data)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('permissions template based on role java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * getRoleBasedPermissions get api
 * to get the default permissions based on role */
export async function getRoleBasedPermissions(roleId) {
  let result = '';
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/rbac/permissions/template/${roleId}`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.data;
      }
    })
    .catch((err) => {
      console.log('permissions template based on role java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * /rbac/permissionss get API
* to get the permissions based on userId,accountId,roleId */
export async function getPermissionsData() {
  let data = [];
  const request = {
    user_id: JSON.parse(localStorage.getItem('userId')),
    role_id: JSON.parse(localStorage.getItem('roleId')),
    account_id: JSON.parse(localStorage.getItem('accountId')),
  };
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/rbac_permission_data`, request)
    .then((response) => {
      if (response.data.data && response.data.status_code === 200) {
        data = response.data.data.rbac_permission_inst;
      }
    })
    .catch((err) => {
      console.log('rbac_permission_data get java api error', err); // eslint-disable-line no-console
    });
  return data;
}
/**
 * getListOfRoes with permissions get api
 * to get the list of roles and permissions */
export async function getListOfRoles() {
  let result = [];
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/rbac/permissions/templates`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.data;
      }
    })
    .catch((err) => {
      console.log('getListOfRoles java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * getListOfServices get api
 * to get the default permissions based on role */
export async function getListOfServicesBasedOnRole(roleId) {
  let result = '';
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/rbac/${roleId}`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.data;
      }
    })
    .catch((err) => {
      console.log('getListOfServicesBasedOnRole java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * getListOfRoleNames get api
 * to Get List of all roles, id and name. */
export async function getListOfRoleNames() {
  let result = '';
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/rbac/roleInfo`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.data;
      }
    })
    .catch((err) => {
      console.log('getListOfRoleNames java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/* updateRoleActiveStatus put api
 * to update Role Is Active status */
export async function updateRoleActiveStatus(roleId, requestData) {
  let result = {};
  await axios.put(`${process.env.VUE_APP_JAVA_API_URL}/rbac/${roleId}`, requestData)
    .then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('put updateRoleActiveStatus java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * saveRole post api
 * to save Role details */
export async function saveRole(request) {
  return await new Promise((resolve, reject) => { // eslint-disable-line no-return-await
    $.ajax({
      url: `${process.env.VUE_APP_JAVA_API_URL}/rbac`,
      data: JSON.stringify(request),
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      headers: {
        Authorization: getCookie('Authorization'),
      },
      success(result) {
        resolve(result);
      },
      error(error) {
        reject(error.responseJSON);
      },
    });
  });
}
/* updateRole ppst api
 * to update Role details */
export async function updateRole(data) {
  let result = {};
  await axios.put(`${process.env.VUE_APP_JAVA_API_URL}/rbac/permissions/${data.role_id}`, data)
    .then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('put updateRole java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/* update Marketplace services put api
 * to update Marketplace services details */
export async function updateMarketplaceSercvies(data) {
  let result = {};
  await axios.put(`${process.env.VUE_APP_JAVA_API_URL}/rbac/premium/services/${data.id}`, data)
    .then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('put updateMarketplaceSercvies java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * getLastRoleId get api
 * to Get recent Role Id. */
export async function getLastRoleId() {
  let result = '';
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/rbac/lastRoleId`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.data;
      }
    })
    .catch((err) => {
      console.log('getLastRoleId java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * getListOfAccountTypes get api
 * to Get List of all account types. */
export async function getListOfAccountTypes() {
  let result = '';
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/rbac/accountType`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.data;
      }
    })
    .catch((err) => {
      console.log('getListOfAccountTypes java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * getListOfUserAccounts get api
 * to Get List of all account types. */
export async function getListOfUserAccounts(userId) {
  let result = [];
  await axios.get(`${process.env.VUE_APP_JAVA_API_URL}/rbac/service/accounts/userId/${userId}`)
    .then((response) => {
      if (response.status === 200 && response.data.status_code === 200) {
        result = response.data.data?.accounts || [];
      }
    })
    .catch((err) => {
      console.log('service/accounts/userId/ java api error', err); // eslint-disable-line no-console
    });
  return result;
}
