/**
 * @author SwathiDivya Bhavaraju
 * @email swathidivya.bhavaraju@ul.com
 * @create date 2020-04-02 17:55:01
 * @modify date 2020-04-02 17:55:01
 * @desc [description]
 */
<template>
  <div id="app">
    <notifications position="bottom right" />
    <custom-notification />
    <router-view />
  </div>
</template>

<script>
import CustomNotification from './components/Common/CustomNotification.vue';

export default {
  name: 'App',
  components: {
    CustomNotification,
  },
  mounted() {
    if (!window.navigator.onLine) {
      this.$router.push('/networkconnection').catch(() => {});
    }
  },
};
</script>
