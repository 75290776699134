/**
 * @author Pawan Sadvale
 * @email Pawan.Sadvale@ul.com
 * @create date 2020-02-26 15:07:41
 * @modify date 2020-02-26 15:07:41
 * @desc [description]
 */
import axios from 'axios';

/**
 * update team member put api
 * to update Team Member role and permissions */
export async function updateTeamMember(data) {
  let result = '';
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/team_member`, data)
    .then((response) => {
      if (response.status === 200 && response.data) result = response.data;
    })
    .catch((err) => {
      console.log('set team_member java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * removeTeamMember delete api
 * to delete Team Member */
export async function removeTeamMember(formData) {
  let data = null;
  await axios
    .delete(`${process.env.VUE_APP_JAVA_API_URL}/team_member`, { data: formData })
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      data = {
        status: false,
      };
      console.log('remove team_member java api error', err); // eslint-disable-line no-console
    });
  return data;
}
/**
 * getTeamMembers get api
 * to get Team Members list */
export async function getTeamMembers(typeId) {
  let result = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/team_member/${typeId}`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('get team_member java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * addTeamMember post api
 * to add new Team Member */
export async function addTeamMember(data) {
  let result = '';
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/team_member`, data)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('add team member java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * addMAATeamMember post api
 * to add new Team Member */
export async function addMAATeamMember(data) {
  let result = '';
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/maa/team_member`, data)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('add MAA team member java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * Mfa get api
 * to get the mfa data */
export async function getMultiFactorAuthentication() {
  let result = '';
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/mfa`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('get mfa java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * Mfa put api
 * to update the two facotr authentication */
export async function updateMultiFactorAuthentication(data) {
  let result = '';
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/mfa`, data)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('put mfa java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * Team Member restriction post api
 * to check the team member has to be restricted or not */
export async function getTeamMemberRestriction(data) {
  let result = '';
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/team_member_restriction`, data)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('post team_member_restriction java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * Team Member restriction post api
 * to check the team member has to be restricted or not */
export async function checkUserRestriction() {
  let result = '';
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/check_restrict_user`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('get check_restrict_user java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/**
 * Team Member restriction put api
 * to update the team member to be un-restricted */
export async function updateTeamMemberRestriction(data) {
  let result = '';
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/team_member_restriction`, data)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('put team_member_restriction jaava api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * Unblock user put api
 * to unblock the user */
export async function unBlockUser(data) {
  let result = '';
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/unrestrict_user`, data)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('put team_member_restriction jaava api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * Account_owner_restriction get api
 * to get the count of existing account owners count */
export async function getAccountOwnersCount(typeId) {
  let result = '';
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/team_member_account_owner_restriction/${typeId}`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('get team_member_account_owner_restriction java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 * restricted-user-count get api
 * to get the count of restricted users for account */
export async function getTeamMemberRestrictedUsersCount(accountId) {
  let result = 0;
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/restricted-user-count/${accountId}`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.count;
      }
    })
    .catch((err) => {
      console.log('get restricted-user-count java api error', err); // eslint-disable-line no-console
    });
  return result;
}

export async function getTeamMemberAddonProRata(payload) {
  let result = 0;
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/addon/account/teamMemberUpgrade`, payload)
    .then((response) => {
      if (response.status === 200) {
        result = response.data.data;
      }
    })
    .catch((err) => {
      console.log('get restricted-user-count python api error', err); // eslint-disable-line no-console
    });
  return result;
}

export async function getMAAAccountOwnersCount(typeId,payload) {
  let result = 0;
  await axios
    .post(`${process.env.VUE_APP_JAVA_API_URL}/maa/team_member_account_owner_restriction/${typeId}`, payload)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('post team_member_account_owner_restriction java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/**
 *  modify team member delete api
 */
export async function modifyTeamMember(formData) {
  let result = '';
  await axios
    .delete(`${process.env.VUE_APP_JAVA_API_URL}/modify_team_member`, { data: formData })
    .then((response) => {
      if (response.status === 200 && response.data) result = response.data;
    })
    .catch((err) => {
      console.log('set team_member java api error', err); // eslint-disable-line no-console
    });
  return result;
}
